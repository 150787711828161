
import "./text-area-v1"
import "./input-v1"
import "./select-box-v2"
import { validateInput } from '@yggdrasil/Utils/inputValidator'
import { CF2Component } from 'javascript/lander/runtime'

export default class AppointmentSchedulerV1 extends CF2Component {

constructor(el, runtimeSel) {
super(el, runtimeSel)
}


  mount() {
    function setupHostDropdown() {
      const el = document.getElementById("hostDropdown")
      if (!el) return

      const button = document.querySelector('#hostDropdown button')
      const options = document.querySelector('#hostDropdown ul')
      button.addEventListener('click', () => {
        options.classList.remove("hidden")
      })
    }

    function processCronofyResponse(data){
      updateSlotSummary(data)
      updateForm(data)
      toggleForm(data)
    }

    function updateSlotSummary(data) {
      const slotInfo = document.getElementById('slotInfo')
      const timeEl = document.getElementById("timeString")
      const zoneEl = document.getElementById("zoneString")

      const slotDate = new Date(data.slot.start)
      const timeString = slotDate.toLocaleString("en-us", {timezone: data.tzid})
      const month = slotDate.toLocaleString("default", {timezone: data.tzid, month: 'long'})
      const [date,time] = timeString.split(", ")
      const [_month, day, year] = date.split("/")

      timeEl.innerText = `${time.slice(0, time.lastIndexOf(":"))}, ${day} ${month} ${year}`
      zoneEl.innerText = data.tzid

      slotInfo?.classList?.remove('hidden')
    }

    function updateForm(data) {
      document.getElementById('appointment_schedule_request_start_on').value = data.slot.start
      document.getElementById('appointment_schedule_request_end_on').value = data.slot.end
      document.getElementById('appointment_schedule_request_tzid').value = data.tzid
    }

    function toggleForm(data) {
      const cal = document.getElementById('cronofy-date-time-picker')
      const form = document.getElementById('formContainer')

      cal?.classList?.add('hidden')
      form?.classList?.remove('hidden')
    }

    if (this.requestFetcher){
      this.requestFetcher.abort()
    }
    this.requestFetcher = null
    this.requestFetcher = new globalThis.CFFetcher()
    const fetchUrl = `/user_pages/api/v1/appointments/event_types/${this.event_type.id}/calendar_config`
    this.requestFetcher.fetch(fetchUrl,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
          host_id: this.event_type?.selected_host?.id,
        })
      }
    )
    .then((response) => response.json())
    .then((data) => {
      this.cronofy = CronofyElements.DateTimePicker({
        element_token: data.cronofy.element_token,
        target_id: 'cronofy-date-time-picker',
        availability_query: JSON.parse(data.cronofy.availability_query_json),
        styles: {
          prefix: 'cf2',
          colors: {
            buttonActive: data.cronofy.active_color,
            buttonHover: data.cronofy.hover_color,
            buttonConfirm: data.cronofy.confirm_color,
          },
        },
        tzid: data.cronofy.tzid,
        callback: (res) => {
          if (res?.notification?.type !== 'slot_selected') return

          processCronofyResponse(res?.notification)
        }
      })
    })

    setupHostDropdown()

    const errors = {}
    const updateErrorDisplay = () => {
      const errorList = document.querySelector('ul.errorList')
      errorList.innerHTML = ''
      if(Object.keys(errors).length === 0) {
        errorList.classList.add('hidden')
      } else {
        for(const key in errors){
          const li = document.createElement("li")
          li.appendChild(document.createTextNode(errors[key]))
          errorList.appendChild(li)
        }
        errorList.classList.remove('hidden')
      }
    }

    const validateOnBlur = (input, inputName) => {
      const result = validateInput(input, {validateAsEmail: input.type === 'email' || input.name?.match(/email/)})
      delete errors[inputName]
      input.style.color = 'black'
      if (result === false) {
        const fieldName = inputName.split('_').map(w => w.charAt(0).toUpperCase() + w.slice(1)).join(' ')
        errors[inputName] = `${fieldName} is invalid.`
        input.style.color = 'red'
      }
      updateErrorDisplay()
    }

    const inputs = document.querySelectorAll('.appointment_schedule_request_field:not(.hidden)')
    inputs.forEach((input) => {
      input.addEventListener('blur', () => validateOnBlur(input, input.name))
    })
    document.querySelector('a[href="#submit-form"]').addEventListener('click', (event) => {
      inputs.forEach((i) => {
        i.dispatchEvent(new Event('blur'))
      })
      document.querySelectorAll(".guest-input").forEach((i) => {
        i.dispatchEvent(new Event('blur'))
      })
    })

    const additionalFields = document.querySelector('.additional-fields-container')
    const addCommentsButton = document.querySelector('.add-comments a')
    const addGuestButton = document.querySelector('.add-guest a')
    const links = document.querySelector('.add-links')
    const commentsContainer = document.querySelector('.comments-container')
    const guestsContainer = document.querySelector('.guests-container')
    const guestEmailTemplate = document.querySelector('.guest-email-template')

    addCommentsButton.addEventListener('click', (evt) => {
      evt.preventDefault()

      commentsContainer.classList?.remove('hidden')
      addCommentsButton.classList?.add('hidden')
    })

    addGuestButton.addEventListener('click', (evt) => {
      evt.preventDefault()
      const inputCount = document.querySelectorAll('.guest-input').length

      const guestInputContainer = guestEmailTemplate.cloneNode(true)
      guestInputContainer.classList.remove('guest-email-template')
      guestInputContainer.classList.add('guest-email')

      const guestInput = guestInputContainer.querySelector('input')
      guestInput.type = 'email'
      guestInput.name = `guest_email_${inputCount + 1}`
      guestInput.classList.add('guest-input')
      guestInput.addEventListener('blur', () => validateOnBlur(guestInput, guestInput.name))

      guestsContainer.appendChild(guestInputContainer)

      const removeGuestButton = guestInputContainer.querySelector('.remove-link')
      removeGuestButton.addEventListener('click', () => {
        const container = document.querySelector('.guests-container')
        console.log({removeGuestButton})
        removeGuestButton.parentElement.remove()
        delete errors[guestInput.name]
        updateErrorDisplay()
        if (guestsContainer.querySelectorAll('.guest-email').length == 0) {
          guestsContainer.classList.add('hidden')
        }
      })

      guestsContainer.classList.remove('hidden')
    })
  }



}

window["AppointmentSchedulerV1"] = AppointmentSchedulerV1

